@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.quicksand-regular {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.quicksand-bold {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.green {
    background-color: #c8d0a5;
    border-color: #c8d0a5;
}

.about-me {
    font-weight: 600;
    font-size: large;
    padding: 5%;
}

.background {
    background-color: mintcream;
    width: 100%;
    background-size: contain;
    background-repeat: repeat;
}

.homeicon {
    min-width: 10rem;
    min-height: 10rem;
    margin: 3rem;
}

.masthead {
    background-image: url("../../public/images/adria_stubbs_extended.jpeg");
    height: 90vh;
    background-position: center 80%;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
}

.subheader {
    color: lightgray;
    font-weight: 500;
    padding-top: 10px;
}

.header-text {
    font-weight: 700;
    margin-left: -600px;
    margin-top: 100px;
}

@media (max-width: 700px) {

    .masthead {
        background-size: cover;
        background-position-x: right;
        height: 55vh;
        position: relative;
    }

    .header-text {
        position: absolute;
        right: -1rem;
        left: auto;
        top: 30%;
        scale: 0.8;
        transform: translateY(-50%);
        font-size: 0.6em;
        text-wrap: wrap;
        max-width: 60%;
        width: auto;
        overflow-wrap: break-word;
        word-break: normal;
    }
}

.jumbotron-wrapper {
    width: 100%;
    overflow-x: hidden;
}

.custom-jumbotron {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    padding: 50px 5000px;
}

.contact-form {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../public/images/pebbles.jpg");
    background-repeat: repeat-y;
    background-size: min(150%, 150%);
    color: white;
}



@media (max-width: 1100px) {
    .header-text {
        margin-left: 0;
        margin-top: 0;
    }
}