$primary: #cee2c4;
$secondary: #77C9D4;
$warning: #A5A5AF;
$light: #f5fffa;
$dark: #015249;
$danger: red;
$success: green;

/* make the customizations */
$theme-colors: (
        'primary': $primary,
        'secondary': $secondary,
        'danger': $danger,
        'light': $light,
        'success': $success,
        'warning' : $warning,
        'dark': $dark
);

.pebble-background {
  background-image: url("../../public/images/pebbles.jpg");
  background-size: cover;

}

/* import bootstrap to set changes */
@import '../../node_modules/bootstrap/scss/bootstrap';